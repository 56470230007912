import React from 'react'
import neda from './neda.jpg'

export default function Praktikant() {
    return (
        <>
            <h2>Personal</h2>

            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 20,
                flexGrow: 1
            }}>

                <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "center", flexGrow: "yes" }}>
                    <img src={neda} alt="neda" height={250} width={180} style={{ marginRight: 10 }} />
                    <p>
                        Nu är Neda tillbaka och jobbar tisdagar framöver.{window.innerWidth > 500 ? <br /> : null} Välkommna att boka era tider hos henne!
                    </p>
                </div>
                {/* <hr width="50%"></hr>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "center", flexGrow: "yes" }}>
                    <img src={homa} alt="homa" height={250} width={150} style={{ marginRight: 10 }} />

                    <p>
                        Homa har en stor passion för färg och form och älskar att vara kreativ och få skapa.{window.innerWidth > 500 ? <br /> : null} Med över 20 års yrkeserfarenhet både nationellt och internationellt har hon stor kunskap i yrkets alla delar.{window.innerWidth > 500 ? <br /> : null} Oavsett ålder och smak, hjälper Homa dig mer än gärna i sin stol!
                    </p>
                </div> */}
            </div>
            {/* <p style={{backgroundColor: "whitesmoke", padding:10, borderRadius: 20}}><b>Kunder är varmt välkomna att boka klipp och slingning/helfärgning till ett förmånligare pris hos mig just nu med <u>25% rabatt!</u></b></p> */}
        </>
    )
}
